<template>
  <div class="card">
    <div class="header-table">
      <div style="font-weight: 500">Entries</div>
      <InputText
        v-model="filters['global'].value"
        placeholder="Search"
        class="p-mb-3"
      />
    </div>
    <DataTable
      :value="entries"
      v-model:filters="filters"
      :paginator="true"
      :rows="20"
      responsiveLayout="stack"
      :rowsPerPageOptions="[20, 50, 100]"
      stripedRows
      class="p-datatable-sm"
    >
      <Column field="firstname" header="First Name"></Column>
      <Column field="lastname" header="Last Name"></Column>
      <Column field="email" header="Email"></Column>
      <Column field="phone" header="Phone"></Column>
      <Column field="country" header="Country">
        <template #body="slotProps">
          <div class="flex items-center gap-2">
            <img
              :src="getCountryFlag(slotProps.data.country)"
              :alt="getCountryName(slotProps.data.country)"
              class="flag-icon"
            />
            <span>{{ slotProps.data.country }}</span>
          </div>
        </template>
      </Column>
      <Column field="city" header="City"></Column>
      <Column field="contesttype" header="Contest Type"></Column>
      <Column field="hardwareused" header="Hardware Used"></Column>
      <Column field="contentlink" header="Content">
        <template #body="slotProps">
          <a
            :href="slotProps.data.contentlink"
            target="_blank"
            class="text-blue-500 hover:text-blue-700"
          >
            {{ slotProps.data.contentlink }}
          </a>
        </template>
      </Column>
      <Column field="projectbrief" header="Project Brief"></Column>
    </DataTable>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";

const entries = ref([]);

const filters = ref({
  global: { value: null, matchMode: "contains" },
});

const countryData = ref([]);

const fetchEntries = async () => {
  try {
    const response = await fetch(
      "https://api.egamena.com/nvidia/studio-3/entries"
    );
    const data = await response.json();
    entries.value = data.data;
  } catch (error) {
    console.error("Error fetching entries:", error);
  }
};

const fetchCountryData = async () => {
  try {
    const response = await fetch("https://restcountries.com/v3.1/all");
    const data = await response.json();
    countryData.value = data;
  } catch (error) {
    console.error("Error fetching country data:", error);
  }
};

const getCountryFlag = (countryCode) => {
  const country = countryData.value.find((c) => c.cca2 === countryCode);
  return country ? country.flags.png : "";
};

const getCountryName = (countryCode) => {
  const country = countryData.value.find((c) => c.cca2 === countryCode);
  return country ? country.name.common : "";
};

onMounted(() => {
  fetchEntries();
  fetchCountryData();
});
</script>

<style scoped>
.card {
  max-width: 1460px;
  width: 100%;
  margin: auto;
  padding: 30px;
}

.flag-icon {
  width: 24px;
  height: auto;
  margin-right: 10px;
}

.header-table {
  display: flex;
  margin-bottom: 14px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.flex {
  display: flex;
  align-items: center;
}

.p-inputtext {
  font-size: 15px;
}

.p-datatable-sm {
  font-size: 15px;
}

</style>
<style>
.p-datatable-column-title {
  white-space: nowrap!important; 
}
.p-datatable-tbody > tr > td {
    padding: 0.75rem 1rem!important;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.5rem 1rem!important;
}
</style>