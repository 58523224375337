<template>
  <div>
    <Toast />
    <div class="not-authorized-container">
      <Card>
        <template #content>
          <div class="main-tlt">Access Granted</div>
          <p class="m-0">You are being redirected to the home page.</p>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: 'LoginSuccess',
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      Cookies.set('authToken', token, { expires: 10 / 24 });
      this.$router.push({ path: '/' });
    }
  }
};
</script>

<style>
.p-toast-message {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000;
}
.not-authorized-container {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
}
.main-tlt {
  color: #ffd63b;
  font-size: 25px;
}
.p-card-body {
    display: flex;
    width: 400px;
    height: 200px;
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
}
</style>
