<template>
  <div class="login-page">
    <div class="spinner-container">
      <ProgressSpinner />
    </div>
  </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';

export default {
  name: 'ProtectedPage',
  components: {
    ProgressSpinner
  },
  beforeMount() {
    this.$router.push('/nvidia-studio');
  }
};
</script>

<style scoped>
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
}
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>
