<template>
  <div class="login-page">
    <div class="spinner-container">
      <ProgressSpinner />
      <h4>Waiting for Admin Connect...</h4>
    </div>
  </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';

export default {
  name: 'LoginRedirect',
  components: {
    ProgressSpinner
  },
  mounted() {
    setTimeout(() => {
      this.redirectToGoogleLogin();
    }, 2000);
  },
  methods: {
    redirectToGoogleLogin() {
      window.location.href = 'https://api.egamena.com/v1/auth/login';
    }
  }
};
</script>

<style scoped>
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
}
.p-progressspinner {
  width: 70px;
}
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
h4 {
  font-weight: 500;
}
.spinner-container p {
  text-align: center;
}
</style>
