import { createRouter, createWebHistory } from 'vue-router';
import LoginSuccess from '../views/LoginSuccess.vue';
import NotAuthorized from '../views/NotAuthorized.vue';
import QrCode from '../views/QrCode.vue';
import ProtectedPage from '../views/ProtectedPage.vue';
import NvidiaStudio from '../views/NvidiaStudio.vue';
import LoginRedirect from '../views/LoginRedirect.vue';
import axios from 'axios';
import Cookies from 'js-cookie'; 

const routes = [
  {
    path: '/login-success',
    name: 'LoginSuccess',
    component: LoginSuccess,
    meta: { title: 'Login Successful - EGA Admin' },
  },
  {
    path: '/not-authorized',
    name: 'NotAuthorized',
    component: NotAuthorized,
    meta: { title: 'Access Denied - EGA Admin' },
  },{
    path: '/qr',
    name: 'QrCode',
    component: QrCode,
    meta: { title: 'QR Code - EGA Admin' },
  },
  {
    path: '/',
    name: 'Protected',
    component: ProtectedPage,
    meta: { requiresAuth: true, title: 'Home - EGA Admin' },
  },
  {
    path: '/nvidia-studio',
    name: 'Nvidia Studio',
    component: NvidiaStudio,
    meta: { requiresAuth: true, title: 'Nvidia Studio 3' },
  },
  {
    path: '/login',
    name: 'LoginRedirect',
    component: LoginRedirect,
    meta: { title: 'Login - EGA Admin' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authToken = Cookies.get('authToken');

  if (to.meta.title) {
    document.title = to.meta.title; 
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authToken) {
      next('/login');
    } else {
      try {
        await axios.get('https://api.egamena.com/v1/auth/token', {
          headers: {
            Authorization: authToken,
          },
        });
        next();
      } catch (error) {
        next('/not-authorized');
      }
    }
  } else {
    next();
  }
});


export default router;
