<template>
  <div>
    <Toast />
    <div class="not-authorized-container">
      <Card>
        <template #content>
          <div class="main-tlt">Access Denied</div>
          <p class="m-0">You are not authorized to access this page.</p>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import Card from "primevue/card";

export default {
  name: "NotAuthorized",
  components: {
    Toast,
    Card,
  },
  mounted() {
    const toast = useToast();
    toast.add({
      severity: "error",
      summary: "Access Denied",
      detail: "You are not authorized to access.",
      life: 5000,
    });
  },
};
</script>

<style>
.p-toast-message {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000;
}
.not-authorized-container {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
}
.main-tlt {
  color: #ffd63b;
  font-size: 25px;
}
.p-card-body {
    display: flex;
    width: 400px;
    height: 200px;
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;

}
</style>
